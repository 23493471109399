import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import NewProjects from "./NewProjects";
import MyProjects from "./MyProjects";
import { Outlet, useParams } from "react-router-dom";

function ArchitectProjects() {
  const [key, setKey] = useState("new-projects");
  const { propertyId } = useParams();
  const [propertyCount, setPropertyCount] = useState(0);
  if (propertyId) {
    return <Outlet />;
  }
  return (
    <div>
      <div
        className="d-flex flex-column border-bottom"
        style={{ background: "#F9FAFB" }}
      >
        <div className="general-page-padding pb-4">
          {key === "new-projects" ? (
            <div>
              <div className="h2 be-vietnam-600">
                New Projects ({propertyCount})
              </div>
              <div className="body-title inter">
                See all the projects available to create designs!
              </div>
            </div>
          ) : (
            <div className="h2 be-vietnam-600">My Projects</div>
          )}
        </div>
      </div>
      <div className="general-page-padding pt-5">
        <Tabs className="mb-3" activeKey={key} onSelect={(k) => setKey(k)}>
          <Tab
            eventKey="new-projects"
            title={`New Projects (${propertyCount})`}
          >
            <NewProjects setPropertyCount={setPropertyCount} />
          </Tab>
          <Tab eventKey="my-projects" title="My Projects">
            <MyProjects />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default ArchitectProjects;
