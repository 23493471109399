import { nav_img1, nav_img2, nav_img3, nav_img4 } from "assets";
import { Col, NavDropdown, Row } from "react-bootstrap";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const InspirationMegaMenu = () => {
  const navigate = useNavigate();
  return (
    <div className="body-text2 inter" style={{ padding: "30px 50px" }}>
      <div
        className="d-flex px-0 align-items-center inter-600 mb-3"
        // onClick={() => navigate("/projects")}
      >
        <a className="nav-link p-0" href="/projects">
          All Projects <MdKeyboardArrowRight size={25} />
        </a>
      </div>
      <div className="d-flex">
        <div className="d-flex flex-column align-items-center">
          <img src={nav_img1} alt="" />
          <div className="body-text3 nav-link">Jewels of India</div>
        </div>
        <div className="d-flex flex-column align-items-center mx-5">
          <img src={nav_img2} alt="" />
          <div className="body-text3 nav-link">The Crown</div>
        </div>
        <div className="d-flex flex-column align-items-center">
          <img src={nav_img3} alt="" />
          <div className="body-text3 nav-link">Melodia Apts</div>
        </div>
        <div className="d-flex flex-column align-items-center mx-5">
          <img src={nav_img4} alt="" />
          <div className="body-text3 nav-link">Mangalam Radiance</div>
        </div>
      </div>
      <div className="d-flex px-0 align-items-center inter-600">
        <a className="nav-link p-0" href="/spaces">
          All Spaces <MdKeyboardArrowRight size={25} />
        </a>
      </div>
      <div className="body-text3 d-flex">
        <div className="me-5">
          <div className="body-text2 inter-600 my-2">Living Area</div>
          <div className="my-2">Living Room</div>
          <div className="my-2">Entrance Foyer</div>
          <div className="my-2">Atriums</div>
          <div className="my-2">Drawing Room</div>
        </div>
        <div className="me-5">
          <div className="body-text2 inter-600 my-2">Bedrooms</div>
          <div className="my-2">Master Bedroom</div>
          <div className="my-2">Kid's Bedroom</div>
          <div className="my-2">Guest Bedroom</div>
        </div>
        <div className="me-5">
          <div className="body-text2 inter-600 my-2">Kitchen and Dining</div>
          <div className="my-2">Kitchen Area</div>
          <div className="my-2">Home Bar Counter</div>
          <div className="my-2">Dining Room</div>
          <div className="my-2">Utility Area</div>
          <div className="my-2">Pantry / Storage Room</div>
        </div>
        <div className="me-5">
          <div className="body-text2 inter-600 my-2">Bathroom</div>
          <div className="my-2">Master Bathroom</div>
          <div className="my-2">Kid's Bathroom</div>
          <div className="my-2">Guest Bathroom</div>
        </div>
        <div className="me-5">
          <div className="body-text2 inter-600 my-2">Other Rooms</div>
          <div className="my-2">Kid's Studyroom</div>
          <div className="my-2">Kid's Playroom</div>
          <div className="my-2">Pooja Room</div>
          <div className="my-2">Home Office</div>
          <div className="my-2">Home Theatre</div>
          <div className="my-2">Home Gym</div>
        </div>
        <div className="me-5">
          <div className="body-text2 inter-600 my-2">Outdoor Spaces</div>
          <div className="my-2">Balconies</div>
          <div className="my-2">Terraces / Patios</div>
          <div className="my-2">Backyard Gardens</div>
          <div className="my-2">Verandas</div>
        </div>
      </div>
    </div>
  );
};

export default InspirationMegaMenu;
