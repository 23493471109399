import { useState } from "react";
// import { login } from "../../reducer/testAuthSlice";
import { login, verifyOtpAndLogin } from "reducer/authSlices";
import { useDispatch } from "react-redux";
import { LuEyeOff, LuMail } from "react-icons/lu";
import { architect_pfp, builder_pfp, user_pfp } from "assets";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { notify } from "utilities/notifications/Notify";
const {
  InputGroup,
  Modal,
  Form,
  Button,
  Row,
  Col,
  Spinner,
} = require("react-bootstrap");

const LoginModal = ({ showLoginModal, setShowLoginModal }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [isHomeOwner, setIsHomeOwner] = useState(true);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otpInput, setOtpInput] = useState(false);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async () => {
    // Perform login logic here, then dispatch the login action
    const data = {
      username: email,
      password: password,
    };
    try {
      const response = await axios.post("/user/login/", data);
      console.log(response);
      if (response.status === 200) {
        dispatch(login(response.data));
        setShowLoginModal(false);
        cleanUpFn();
        navigate("/");
      }
    } catch (error) {
      notify(error.response.data.msg || "An error occured!", "error");
    }
  };

  const handleSignUp = async () => {
    setLoading(true);
    if (confirmPassword !== password) {
      notify("Password didnt match");
      setLoading(false);
      return;
    }
    const data = {
      full_name: fullName,
      username: email,
      email: email,
      password: password,
      user_type: "customer",
    };
    try {
      const response = await axios.post("/user/signup/", data);
      if (response.status === 201) {
        setOtpInput(true);
        setLoading(false);
      }
    } catch (error) {
      notify(error.response.data.msg || "An error occured!", "error", 1);
    }
  };

  // const handleKeyPress = (event) => {
  //   if (event.key === 'Enter') {
  //     handleLogin();
  //   }
  // };

  const verifyOtpLogin = async () => {
    const data = {
      username: email,
      email_verified_otp: otp,
    };
    try {
      const response = await axios.post("/user/verify/", data);
      console.log(response);
      if (response.status === 200) {
        dispatch(verifyOtpAndLogin(response.data));
      }
    } catch (error) {
      notify(error.response.data.msg || "An error occured!", "error");
    }
    setShowLoginModal(false);
    cleanUpFn();
    navigate("/");
  };

  const cleanUpFn = () => {
    setFullName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setOtpInput(false);
  };

  return (
    <Modal
      show={showLoginModal}
      onHide={() => {
        cleanUpFn();
        setShowLoginModal(false);
        setIsLogin(true);
        setIsHomeOwner(true);
      }}
    >
      <div className="py-3 px-4">
        <Modal.Header style={{ border: 0 }} closeButton />
        {isHomeOwner ? (
          <Modal.Body>
            <div className="d-flex flex-column justify-content-center align-items-center text-center ">
              <div
                className="be-vietnam-700 text-black"
                style={{ fontSize: "30px", color: "black" }}
              >
                {isLogin ? "Welcome" : "Signup"} to Inter!okeys
              </div>
              <div style={{ fontSize: "13px", color: "black" }}>
                Select from a collection of top-notch designs created by our
                professionals for your home interiors
              </div>
            </div>

            <Form className="my-3">
              <Form.Group>
                <Form.Label className="text-black">
                  Company Email<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text
                    className="border-dark"
                    style={{ backgroundColor: "white" }}
                  >
                    <LuMail />
                  </InputGroup.Text>
                  <Form.Control
                    className="border-dark border-start-0 px-0"
                    type="email"
                    name="email"
                    placeholder="example@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
              {isLogin ? (
                <Form.Group className="mt-3">
                  <Form.Label className="text-black">
                    Password<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text
                      className="border-dark"
                      style={{ backgroundColor: "white" }}
                    >
                      <LuEyeOff />
                    </InputGroup.Text>
                    <Form.Control
                      className="border-dark border-start-0 px-0"
                      type="password"
                      name="password"
                      placeholder="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                  <div
                    className="body-text2 text-end mb-3"
                    style={{ cursor: "pointer" }}
                  >
                    Forgot Password?
                  </div>
                </Form.Group>
              ) : (
                <>
                  <Form.Group className="my-3">
                    <Form.Label>
                      Name<sup style={{ color: "red" }}>*</sup>
                    </Form.Label>
                    <Form.Control
                      name="full_name"
                      placeholder="Name"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="my-3">
                    <Form.Label className="text-black">
                      Create Password<sup style={{ color: "red" }}>*</sup>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text
                        className="border-dark"
                        style={{ backgroundColor: "white" }}
                      >
                        <LuEyeOff />
                      </InputGroup.Text>
                      <Form.Control
                        className="border-dark border-start-0 px-0"
                        type="password"
                        name="password"
                        placeholder="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="my-3">
                    <Form.Label className="text-black">
                      Confirm Password<sup style={{ color: "red" }}>*</sup>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text
                        className="border-dark"
                        style={{ backgroundColor: "white" }}
                      >
                        <LuEyeOff />
                      </InputGroup.Text>
                      <Form.Control
                        className="border-dark border-start-0 px-0"
                        type="password"
                        name="confirm_password"
                        placeholder="confirm password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  {otpInput && (
                    <Form.Group className="my-3">
                      <Form.Label>
                        OTP<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Row className="">
                        <Col xs="6">
                          <Form.Control
                            name="email_verified_otp"
                            placeholder="Enter otp"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                          />
                        </Col>
                        <Col xs="3">
                          <Button
                            variant="success"
                            className="w-100 text-white"
                            onClick={() => verifyOtpLogin()}
                          >
                            Verify
                          </Button>
                        </Col>
                        <Col xs="3">
                          <Button className="text-white w-100">Resend</Button>
                        </Col>
                      </Row>
                      <Form.Text className="text-success">
                        OTP has been sent to the email address
                      </Form.Text>
                    </Form.Group>
                  )}
                </>
              )}

              <Button
                onClick={() => (isLogin ? handleLogin() : handleSignUp())}
                className="bg-primary py-2"
                style={{
                  fontWeight: "600",
                  color: "#fff",
                  border: 0,
                  width: "100%",
                }}
              >
                {loading && <Spinner animation="border" size="sm" />}{" "}
                {isLogin ? "Login" : "Signup"}
              </Button>
              <div className="my-3 text-center">(OR)</div>
              <Button
                className="bg-info py-2"
                style={{
                  fontWeight: "600",
                  color: "#9e3838",
                  border: 0,
                  width: "100%",
                }}
              >
                {isLogin ? "Login using Gmail" : "Signup using Gmail"}
              </Button>
              {isLogin ? (
                <div className="body-text2 text-center">
                  Not a member?{" "}
                  <span
                    className="text-primary be-vietnam-700"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      cleanUpFn();
                      setIsLogin(!isLogin);
                    }}
                  >
                    Join now!
                  </span>
                </div>
              ) : (
                <div className="body-text2 text-center">
                  Already a member?{" "}
                  <span
                    className="text-primary be-vietnam-700"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      cleanUpFn();
                      setIsLogin(!isLogin);
                    }}
                  >
                    Sign in!
                  </span>
                </div>
              )}
              <Button
                className="bg-white py-2 mt-5"
                style={{
                  fontWeight: "600",
                  color: "#9e3838",
                  width: "100%",
                  border: "2px solid",
                }}
                onClick={() => {
                  setIsHomeOwner(!isHomeOwner);
                  cleanUpFn();
                }}
              >
                Join as a Partner
              </Button>
            </Form>
          </Modal.Body>
        ) : (
          <Modal.Body>
            <div className="d-flex flex-column justify-content-center align-items-center text-center ">
              <div
                className="be-vietnam-700 text-black"
                style={{ fontSize: "30px", color: "black" }}
              >
                Login to Inter!okeys
              </div>
              <div style={{ fontSize: "13px", color: "black" }}>
                As a builder, gain exposure to millions of homeowners and get
                millions of inspirations made for your properties
              </div>
            </div>

            <Form className="my-3">
              <Form.Group>
                <Form.Label className="text-black">
                  Company Email<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text
                    className="border-dark"
                    style={{ backgroundColor: "white" }}
                  >
                    <LuMail />
                  </InputGroup.Text>
                  <Form.Control
                    className="border-dark border-start-0 px-0"
                    type="email"
                    name="email"
                    placeholder="example@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label className="text-black">
                  Password<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text
                    className="border-dark"
                    style={{ backgroundColor: "white" }}
                  >
                    <LuEyeOff />
                  </InputGroup.Text>
                  <Form.Control
                    className="border-dark border-start-0 px-0"
                    type="password"
                    name="password"
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
                <div
                  className="body-text2 text-end mb-3"
                  style={{ cursor: "pointer" }}
                >
                  Forgot Password?
                </div>
              </Form.Group>
              <Button
                className="bg-primary py-2"
                style={{
                  fontWeight: "600",
                  color: "#fff",
                  border: 0,
                  width: "100%",
                }}
                onClick={() => handleLogin()}
              >
                Login
              </Button>
              <div className="my-3 text-center">(OR)</div>
              <Button
                className="bg-info py-2"
                style={{
                  fontWeight: "600",
                  color: "#9e3838",
                  border: 0,
                  width: "100%",
                }}
              >
                Login using Phone Number
              </Button>

              <div className="body-text2 text-center">
                Don't have an account?{" "}
                <span
                  className="text-primary be-vietnam-700"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowLoginModal(false);
                    navigate("/partner-signup");
                  }}
                >
                  Register!
                </span>
              </div>
              <Button
                className="bg-white py-2 mt-5"
                style={{
                  fontWeight: "600",
                  color: "#9e3838",
                  width: "100%",
                  border: "2px solid",
                }}
                onClick={() => {
                  setIsHomeOwner(!isHomeOwner);
                  cleanUpFn();
                }}
              >
                Join as a Homeowner
              </Button>
            </Form>
          </Modal.Body>
        )}
      </div>
    </Modal>
  );
};

export default LoginModal;
