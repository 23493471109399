import { useNavigate } from "react-router-dom";

const { MdKeyboardArrowRight } = require("react-icons/md");

const BrandMegaMenu = () => {
  const navigate = useNavigate();
  return (
    <div className="body-text2 inter" style={{ padding: "30px 50px" }}>
      <div
        className="d-flex px-0 align-items-center inter-600 mb-2 nav-link"
        // onClick={() => navigate("/brands")}
      >
        <a className="nav-link p-0" href="/brands">
          All Brands <MdKeyboardArrowRight size={25} />
        </a>
      </div>
      <div className="px-0 inter-600 nav-link">
        Timber, Plywood and Laminates
      </div>
      <div className="body-text3 d-flex">
        <div className="me-5">
          <div className="body-text2 inter-600 my-2">Veneers</div>
          <div className="my-2">Decowood Veneer</div>
          <div className="my-2">Century Ply</div>
          <div className="my-2">Billion Veneers</div>
          <div className="my-2">Archidply</div>
          <div className="my-2">Ranger Veneers</div>
        </div>
        <div className="me-5">
          <div className="body-text2 inter-600 my-2">Plywood</div>
          <div className="my-2">Century Ply</div>
          <div className="my-2">Green Plywood</div>
          <div className="my-2">Austin Plywood</div>
          <div className="my-2">Sabari Plywood</div>
        </div>
        <div className="me-5">
          <div className="body-text2 inter-600 my-2">Decoratives</div>
          <div className="my-2">Europratik</div>
          <div className="my-2">Rang</div>
          <div className="body-text2 my-2 inter-600">Wallpapers</div>
          <div className="my-2">Life and Colors</div>
          <div className="my-2">D-Decor</div>
        </div>
        <div className="me-5">
          <div className="body-text2 inter-600 my-2">Laminates</div>
          <div className="my-2">Royale Touche</div>
          <div className="my-2">Damas Laminates</div>
          <div className="my-2">Merino Laminates</div>
          <div className="my-2">Greenlam</div>
          <div className="my-2">Virgo Laminates</div>
        </div>
        <div className="me-5">
          <div className="body-text2 inter-600 my-2">Adhesive</div>
          <div className="my-2">Fevicol-Pidilite</div>
          <div className="my-2">Jivanjor-Jubilant</div>
          <div className="my-2">Astral</div>
        </div>
      </div>
    </div>
  );
};

export default BrandMegaMenu;
