import { combineReducers } from "@reduxjs/toolkit";
import utilitiesReducer from "../reducer/utilitySlice";
import authReducer from "../reducer/authSlices";

const rootReducer = combineReducers({
  utilities: utilitiesReducer,
  auth: authReducer,
});

export default rootReducer;
