import React, { useRef, useState } from 'react';
import { maintenance_background_img, maintenance_logo } from 'assets';
import { Navbar, Container, Row, Col, Image, Form, Button, Spinner } from 'react-bootstrap';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import "./Maintenance.css";
import { RiInstagramFill, RiFacebookFill } from "react-icons/ri";
import { notify } from 'utilities/notifications/Notify';
import axios from 'axios';

const Maintenance = () => {

    const form = useRef();
    const [isSending, setIsSending] = useState(false);

    const notifyUser = async (e) => {
        e.preventDefault();
        setIsSending(true);
        const formData = new FormData(form.current);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}marketing/notify/`, formData);
            if (response.status === 201) {
                form.current.reset();
                notify(
                    "Thank you for sharing your email!",
                    "success"
                );
            } else {
                notify("There was some problem saving the email!", "error");
            }
        } catch (err) {
            notify("There was some problem saving the email!", "error");
        }
        setIsSending(false);
    }

    return (
        <div className='user-select-none' style={{ fontFamily: "'Be Vietnam', sans-serif", color: '#555555' }}>
            <Navbar className='fixed-top' bg="white" variant="dark">
                <Container>
                    <Navbar.Brand href="#home">
                        <Image style={{ width: 200 }} src={maintenance_logo} alt="Interiokeys Logo" />
                    </Navbar.Brand>
                </Container>
            </Navbar>
            <Container fluid className="mt-5">
                <Row>
                    <Col md={4} className='d-flex justify-content-center pt-5 ps-lg-5 mb-5'>
                        <div className='px-4 px-lg-auto'>
                            <div className='d-flex flex-column p-5'>
                                <h2 className='my-4'>
                                    Transforming Soon. <br /><br />Stunning Interiors Await!
                                </h2>
                                <div className='my-3' style={{ color: "#D95656" }}>
                                    Sign up to be the first <br />
                                    to know when we launch.
                                </div>
                                <Form ref={form} className="my-3" onSubmit={(e) => notifyUser(e)}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Enter your email here*</Form.Label>
                                        <Form.Control type="email" placeholder="" name="email" required style={{ border: 'none', borderBottom: '1px solid #000', borderRadius: '0' }} />
                                    </Form.Group>
                                    <Button className="text-white px-5 py-2 my-3" disabled={isSending} variant="primary" type="submit" style={{ backgroundColor: '#414141', border: 'none', borderRadius: 0 }}>
                                        {isSending ? (
                                            <span>
                                                <Spinner size="sm" className="me-2" />
                                                Notifying...
                                            </span>
                                        ) : (
                                            <span>Notify Me</span>
                                        )}
                                    </Button>
                                </Form>
                                {/* <div className="d-flex mt-4">
                                    <a className="mx-2" href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                        <RiFacebookFill size={30} color="#555" />
                                    </a>
                                    <a className="mx-2" href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                                        <RiInstagramFill size={30} color="#555" />
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </Col>
                    <Col md={8}>
                        <div className="p-lg-3 px-0">
                            <Image className='full-height-image' src={maintenance_background_img} />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className='px-lg-5' fluid>
                <Row className="px-lg-5 px-4 p-3">
                    <Col className='my-2' md={6}>
                        <Row>
                            <Col className='text-lg-end' lg={2}>
                                Contact
                            </Col>
                            <Col lg={1} className='d-flex align-items-center align-items-lg-start py-lg-3'>
                                <div style={{ width: 30, height: 1, backgroundColor: "#777" }}></div>
                            </Col>
                            <Col lg={3} className='d-flex flex-column align-items-end'>
                                {/* <div>
                                    +91 98280 14776
                                </div> */}
                                <div>
                                    info@interiokeys.com
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    {/* <Col className='my-2' md={6}>
                        <Row>
                            <Col className='text-lg-end' lg={{ span: 2, offset: 4 }}>
                                Experience Center
                            </Col>
                            <Col lg={1} className='d-flex align-items-center align-items-lg-start py-lg-3'>
                                <div style={{ width: 30, height: 1, backgroundColor: "#777" }}></div>
                            </Col>
                            <Col lg={5} className='d-flex flex-column align-items-end'>
                                <div>
                                    283, C & D, Dr. Rajendra Prasad Nagar, Near Mansarovar Metro Station, 200 Feet Bypass Rd, Jaipur, Rajasthan 302019
                                </div>
                            </Col>
                        </Row>
                    </Col> */}
                </Row>
            </Container>
            <Container>
                <Row className="mt-4 py-4">
                    <Col className="text-center">
                        <div style={{ letterSpacing: '4px', fontWeight: "500" }}>
                            <small>
                                Discover executable inspirations for cities elite properties crafted by<br /> creative designers for your ideal living space.
                            </small>
                        </div>
                    </Col>
                </Row>
            </Container>
            <footer style={{ backgroundColor: "#D95656" }} className="mt-4 p-4 text-white text-center">
                © 2024 Interiokeys. All rights reserved.
            </footer>
        </div>
    );
};

export default Maintenance;
