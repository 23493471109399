import {
  architect_pfp1,
  architect_pfp2,
  architect_pfp3,
  architect_pfp4,
  architect_pfp5,
  architect_pfp6,
  architect_project1,
  architect_project2,
  architect_project3,
  architect_project4,
  architect_project5,
  architect_project6,
  asian_paints,
  bosch,
  decowood,
  ego,
  jivanjor,
  kahrs,
  kohler,
  melodia_apartment,
  merino,
  royale,
  saint_gobain,
  samsung,
  schneider,
  user_pfp,
  vitra,
  bedroom1,
  bedroom2,
  bedroom3,
  diningroom1,
  diningroom2,
  diningroom3,
  foyer1,
  foyer2,
  guestroom1,
  guestroom2,
  livingroom1,
  livingroom2,
  livingroom3,
  wineroom1,
  product1,
  product2,
  product3,
  product4,
  property1,
  property2,
  property3,
  property4,
  layout1,
  builder_pfp,
} from "assets";

const projectData = new Promise((resolve, reject) => {
  const data = [
    {
      id: 1,
      name: "Melodia Apartment",
      title: "2BHK, Melodia Apartment",
      type: "2BHK, Block C, 1200Sft",
      architect: "SWJ Architects",
      location: "Vaishali Nagar, Jaipur, India",
      builders: "Suncity Projects",
      spaces: [
        { space_name: "Living Room", image: melodia_apartment, isSaved: true },
        { space_name: "Bedroom", image: bedroom1 },
        { space_name: "Foyer", image: foyer1 },
      ],
      isSaved: true,
    },
    {
      id: 2,
      name: "The Crown",
      title: "3.5BHK, The Crown",
      type: "3.5BHK, Block A, 2450Sft",
      architect: "ArchiLabs",
      location: "Vaishali Nagar, Jaipur, India",
      builders: "Suncity Projects",
      spaces: [
        { space_name: "Wine Room", image: wineroom1 },
        { space_name: "Guest Room", image: guestroom1 },
        { space_name: "Bedroom", image: bedroom3 },
      ],
    },
    {
      id: 3,
      name: "Jewel of India",
      title: "3.5BHK, Jewel of India",
      type: "3.5BHK, Block A, 2450Sft",
      architect: "ArchiLabs",
      location: "Vaishali Nagar, Jaipur, India",
      builders: "Suncity Projects",
      spaces: [
        { space_name: "Living Room", image: livingroom2, isSaved: true },
        { space_name: "Dining Room", image: diningroom2, isSaved: true },

        {
          space_name: "Master Bedroom",
          image: bedroom1,
          isSaved: true,
        },
        {
          space_name: "Bedroom",
          image: bedroom2,
          isSaved: true,
        },
      ],
    },
    {
      id: 4,
      name: "Mangalam Residence",
      title: "3.5BHK, Mangalam Residence",
      type: "3.5BHK, BLock A, 2450Sft",
      architect: "Studio Patel",
      location: "Jawaharlal Nehru Marg, Jaipur, India",
      builders: "Suncity Projects",
      spaces: [
        { space_name: "Guest Room", image: guestroom2 },
        { space_name: "Living Room", image: livingroom3 },

        { space_name: "Dining Room", image: diningroom1 },
      ],
      isSaved: true,
    },

    {
      id: 6,
      name: "Jewel of India",
      title: "2BHK,  Jewel of India",
      type: "3.5BHK, Block A, 1200Sft",
      architect: "ArchiLabs",
      location: "Vaishali Nagar, Jaipur, India",
      builders: "Suncity Projects",
      spaces: [
        { space_name: "Living Room", image: livingroom1, isSaved: true },
        { space_name: "Guest Room", image: guestroom1 },
        { space_name: "Bedroom", image: bedroom1 },
      ],
    },
    {
      id: 7,
      name: "Arcadia Greens",
      title: "2.5BHK, Arcadia Greens",
      type: "2.5BHK, Block C, 1280Sft",
      architect: "ArchiLabs",
      location: "Vaishali Nagar, Jaipur, India",
      builders: "Suncity Projects",
      spaces: [
        { space_name: "Living Room", image: livingroom1 },
        { space_name: "Dining Room", image: diningroom2, isSaved: true },
        { space_name: "Wine Room", image: wineroom1, isSaved: true },
      ],
    },
    {
      id: 8,
      name: "Pride Ajmer",
      title: "2.5BHK, Pride Ajmer",
      type: "2.5BHK, Block C, 1280Sft",
      architect: "SWJ Architects",
      location: "Vaishali Nagar, Jaipur, India",
      builders: "Suncity Projects",
      spaces: [
        { space_name: "Bedroom", image: bedroom2 },
        { space_name: "Dining Room", image: diningroom3 },
        { space_name: "Foyer", image: foyer1 },
      ],
      isSaved: true,
    },
    {
      id: 9,
      name: "Mangalam Residence",
      title: "3.5BHK, Mangalam Residence",
      type: "3.5BHK, BLock A, 2450Sft",
      architect: "Studio Patel",
      location: "Jawaharlal Nehru Marg, Jaipur, India",
      builders: "Suncity Projects",
      spaces: [
        { space_name: "Dining Room", image: diningroom1 },
        { space_name: "Foyer", image: foyer2 },
        { space_name: "Bedroom", image: bedroom1 },
      ],
    },
  ];
  resolve(data);
});

const brandDisplayData = new Promise((resolve, reject) => {
  const data = [
    { id: 1, name: "kohler", image: kohler },
    { id: 2, name: "asian_paints", image: asian_paints },
    { id: 3, name: "saint_gobain", image: saint_gobain },
    { id: 4, name: "bosch", image: bosch },
    { id: 5, name: "samsung", image: samsung },
    { id: 6, name: "schneider", image: schneider },
  ];
  resolve(data);
});

const brandData = new Promise((resolve, reject) => {
  const data = [
    {
      id: 1,
      name: "Ego Flooring",
      type: "Wood and Vinyl Flooring",
      warranty: "Lifetime*",
      rating: 4.6,
      avg_price: "300-500 Rs/Kg",
      image: ego,
    },
    {
      id: 2,
      name: "Jivanjor Adhesives",
      type: "Adhesives",
      warranty: "10+ years",
      rating: 4.8,
      avg_price: "300-500 Rs/Kg",
      image: jivanjor,
    },
    {
      id: 3,
      name: "Decowood",
      type: "Natural, Teak, Engineered Wood",
      warranty: "10+ years",
      rating: 4.3,
      avg_price: "300-500 Rs/Kg",
      image: decowood,
    },
    {
      id: 4,
      name: "Merino Laminates",
      type: "HPL, Compacts, Panels, Wall Cladding, etc",
      warranty: "10+ years",
      rating: 4.2,
      avg_price: "300-500 Rs/Kg",
      image: merino,
    },
    {
      id: 5,
      name: "Kahrs",
      type: "Laminates, Wooden Floors, Plywoods",
      warranty: "10+ years",
      rating: 4.7,
      avg_price: "300-500 Rs/Kg",
      image: kahrs,
    },
    {
      id: 6,
      name: "Vitra",
      type: "Sanitaryware",
      warranty: "10+ years",
      rating: 4.5,
      avg_price: "300-500 Rs/Kg",
      image: vitra,
    },
    {
      id: 7,
      name: "Royale Touche",
      type: "Luxury Laminates",
      warranty: " 10+ years",
      rating: 4.3,
      avg_price: "300-500 Rs/Kg",
      image: royale,
    },
  ];
  resolve(data);
});

const professionalData = new Promise((resolve, reject) => {
  const data = [
    {
      id: 1,
      name: "ArchiLabs",
      type: "Architects and Interior designers",
      location: "Jaipur, India",
      rating: 4.5,
      completed_projects: "50+ projects",
      expertise: "Luxury residential interiors, Elevation Designs, etc",
      project_img: architect_project1,
      profile_img: architect_pfp1,
      isSaved: true,
    },
    {
      id: 2,
      name: "Sameep Padora and Associates",
      type: "Architects and Interior designers",
      location: "Bangalore, India",
      rating: 4.3,
      completed_projects: "85+ projects",
      expertise: "Luxury residential interiors, Elevation Designs, etc",
      project_img: architect_project2,
      profile_img: architect_pfp2,
    },
    {
      id: 3,
      name: "Studio Patel",
      type: "Architects and Interior designers",
      location: "Jaipur, India",
      rating: 4.8,
      completed_projects: "56+ projects",
      expertise: "Luxury residential interiors, Elevation Designs, etc",
      project_img: architect_project3,
      profile_img: architect_pfp3,
      isSaved: true,
    },
    {
      id: 4,
      name: "WJ Studio",
      type: "Architects and Interior designers",
      location: "Hyderabad, India",
      rating: 5.0,
      completed_projects: "40+ projects",
      expertise: "Luxury residential interiors, Elevation Designs, etc",
      project_img: architect_project4,
      profile_img: architect_pfp4,
      isSaved: true,
    },
    {
      id: 5,
      name: "Harshavardhan and Designers",
      type: "Architects and Interior designers",
      location: "Jaipur, India",
      rating: 4.3,
      completed_projects: "80+ projects",
      expertise: "Luxury residential interiors, Elevation Designs, etc",
      project_img: architect_project5,
      profile_img: architect_pfp5,
    },
    {
      id: 6,
      name: "Kundoo Associates",
      type: "Architects and Interior designers",
      location: "Bangalore, India",
      rating: 4.5,
      completed_projects: "50+ projects",
      expertise: "Luxury residential interiors, Elevation Designs, etc",
      project_img: architect_project6,
      profile_img: architect_pfp6,
    },
  ];
  resolve(data);
});

const builderData = new Promise((resolve, reject) => {
  const data = [
    {
      id: 1,
      name: "Suncity Builders",
      image: builder_pfp,
      location: "Corporate Office, Delhi, India",
      ratings: 4.8,
      properties: "Jewel of India, Suncity Township, etc.",
    },
  ];
  resolve(data);
});

const reviewData = new Promise((resolve, reject) => {
  const data = [
    {
      id: 1,
      name: "Tanisha Dutta",
      rating: 4.5,
      type: "Turnkey Interiors Solution",
      content:
        "This guys is true professional. From the initial meeting and drawings through punch out lists and actually getting the key, Dikshant and his crew were amazing. I’d 100% recommend using them for any project!",
    },
    {
      id: 2,
      name: "Nisha Raj",
      rating: 4.6,
      type: "Turnkey Interiors Solution",
      content:
        "I highly recommend Dikshant Sharma to anyone looking for a professional and skilled architect. They are truly a master at their craft and I would not hesitate to work with them again in the future. Their understanding of the building codes and regulations was exceptional and they were able to navigate through all the necessary permits and approvals with ease.",
    },
    {
      id: 3,
      name: "Piyush Goyal",
      rating: 4.6,
      type: "Turnkey Interiors Solution",
      content:
        "I had the pleasure of working with Piyush Rai from ArchiLabs on a recent construction project and I must say, it was a truly professional experience. Their attention to detail and ability to think outside the box were evident from the start, and their creativity and expertise were invaluable in bringing our project to life.",
    },
  ];
  resolve(data);
});

const userData = new Promise((resolve, reject) => {
  const data = [
    {
      name: "Rohan Biradar",
      image: user_pfp,
      email: "rohanbiradar@gmail.com",
      number: "8359872234",
      address: "Test address",
      user_type: "customer",
    },
  ];
  resolve(data);
});

const productData = new Promise((resolve, reject) => {
  const data = [
    {
      id: 1,
      name: "Coffee Table-Madrid",
      brand: "BoConcept",
      description:
        "Created with rubber wood and metal in a minimal design. It can hold upto 20kgs of load and is scratchproof",
      image: product1,
    },
    {
      id: 2,
      name: "Oshawa Area Rug",
      brand: "D-Decor",
      description:
        "The rug boasts a plush and velvety texture, providing a luxurious feel underfoot.",
      image: product2,
    },
    {
      id: 3,
      name: "Birds of Paradise Wallpaper",
      brand: "Life and Colors",
      description:
        "This wallpaper exudes a sense of tranquility and sophistication with its charming beige backdrop",
      image: product3,
    },
    {
      id: 4,
      name: "Wall Panels-Patterned",
      brand: "Decorwood",
      description:
        "Crafted from high-quality, durable materials, these wall panels are both visually striking and resilient..",
      image: product4,
    },
  ];
  resolve(data);
});

const propertyData = new Promise((reslove, reject) => {
  const data = [
    {
      id: 1,
      name: "Jewel of India",
      image: property1,
      layout_img: layout1,
      no_of_flats: 800,
      layout_types: "2BHk (980Sft), 3BHK (1280Sft), 3.5BHK (1350Sft)",
      project_type: "Apartment",
      location: "jaipur, India",
      builder: "Suncity Builders",
      inspirations: [
        {
          id: 1,
          name: "Melodia Apartment",
          title: "2BHK, Melodia Apartment",
          type: "2BHK, Block C, 1200Sft",
          architect: "SWJ Architects",
          location: "Vaishali Nagar, Jaipur, India",
          builders: "Suncity Projects",
          spaces: [
            {
              space_name: "Living Room",
              image: melodia_apartment,
              isSaved: true,
            },
            { space_name: "Bedroom", image: bedroom1 },
            { space_name: "Foyer", image: foyer1 },
          ],
          isSaved: true,
        },
        {
          id: 2,
          name: "The Crown",
          title: "3.5BHK, The Crown",
          type: "3.5BHK, Block A, 2450Sft",
          architect: "ArchiLabs",
          location: "Vaishali Nagar, Jaipur, India",
          builders: "Suncity Projects",
          spaces: [
            { space_name: "Wine Room", image: wineroom1 },
            { space_name: "Guest Room", image: guestroom1 },
            { space_name: "Bedroom", image: bedroom3 },
          ],
        },
        {
          id: 3,
          name: "Jewel of India",
          title: "3.5BHK, Jewel of India",
          type: "3.5BHK, Block A, 2450Sft",
          architect: "ArchiLabs",
          location: "Vaishali Nagar, Jaipur, India",
          builders: "Suncity Projects",
          spaces: [
            { space_name: "Living Room", image: livingroom2, isSaved: true },
            { space_name: "Dining Room", image: diningroom2, isSaved: true },

            {
              space_name: "Master Bedroom",
              image: bedroom1,
              isSaved: true,
            },
            {
              space_name: "Bedroom",
              image: bedroom2,
              isSaved: true,
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: "Urbana Jewel",
      image: property2,
      layout_img: layout1,
      no_of_flats: 800,
      layout_types: "2BHk (980Sft), 3BHK (1280Sft), 3.5BHK (1350Sft)",
      project_type: "Apartment",
      location: "jaipur, India",
      builder: "Suncity Builders",
    },
    {
      id: 3,
      name: "Melodia Apartments",
      image: property3,
      layout_img: layout1,
      no_of_flats: 800,
      layout_types: "2BHk (980Sft), 3BHK (1280Sft), 3.5BHK (1350Sft)",
      project_type: "Apartment",
      location: "jaipur, India",
      builder: "Suncity Builders",
      inspirations: [
        {
          id: 8,
          name: "Pride Ajmer",
          title: "2.5BHK, Pride Ajmer",
          type: "2.5BHK, Block C, 1280Sft",
          architect: "SWJ Architects",
          location: "Vaishali Nagar, Jaipur, India",
          builders: "Suncity Projects",
          spaces: [
            { space_name: "Bedroom", image: bedroom2 },
            { space_name: "Dining Room", image: diningroom3 },
            { space_name: "Foyer", image: foyer1 },
          ],
          isSaved: true,
        },
        {
          id: 9,
          name: "Mangalam Residence",
          title: "3.5BHK, Mangalam Residence",
          type: "3.5BHK, BLock A, 2450Sft",
          architect: "Studio Patel",
          location: "Jawaharlal Nehru Marg, Jaipur, India",
          builders: "Suncity Projects",
          spaces: [
            { space_name: "Dining Room", image: diningroom1 },
            { space_name: "Foyer", image: foyer2 },
            { space_name: "Bedroom", image: bedroom1 },
          ],
        },
      ],
    },
    {
      id: 4,
      name: "Arcadia Greens",
      image: property4,
      layout_img: layout1,
      no_of_flats: 800,
      layout_types: "2BHk (980Sft), 3BHK (1280Sft), 3.5BHK (1350Sft)",
      project_type: "Apartment",
      location: "jaipur, India",
      builder: "Suncity Builders",
    },
  ];
  reslove(data);
});

export {
  projectData,
  brandDisplayData,
  brandData,
  professionalData,
  reviewData,
  userData,
  productData,
  propertyData,
  builderData,
};
